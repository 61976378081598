<template>
  <div class="auth-wrapper">
    <div class="col-md-4 mx-auto form-holder text-center py-5">
      <h3 class="ana_h3 ana_text-grey">{{ verifyMessage }}</h3>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "VerifyEmail",
  data() {
    return {
      verifyMessage: "Processing",
    };
  },
  mounted() {
    console.log(this.$route.params.token);
    const token = {
      token: this.$route.params.token,
    };
    console.log(token);
    axios
      .post("user/verify", token)
      .then((res) => {
        if (res.data.verified) {
          this.verifyMessage =
            "Your account has been verified, you can close this page";
        } else {
          this.verifyMessage = "Something went wrong. Contact support";
        }
      })
      .catch((err) => {
        console.log(err);
        this.verifyMessage = "Something went wrong. Contact support";
      });
  },
};
</script>
